.heading {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.list {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--heading-h4-size);
}
.quoteIcon,
.testimonialBackground {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.quoteIcon {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 4rem 0;
}
.testimonialBackground {
  width: 82.625rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-3xl) var(--padding-144xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--heading-h2-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 750px) {
  .heading {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .quoteIcon {
    gap: 2rem 0;
  }
  .testimonialBackground {
    padding-bottom: var(--padding-87xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .quoteIcon {
    gap: 1rem 0;
  }
}
