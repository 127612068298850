.background {
  align-self: stretch;
  height: 23.75rem;
  position: relative;
  background-color: var(--light-orange);
  display: none;
}
.icon {
  width: 3rem;
  height: 3rem;
}
.aboutUs,
.icon,
.loremIpsumDolor {
  position: relative;
  z-index: 1;
}
.aboutUs {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.loremIpsumDolor {
  width: 15.688rem;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  display: inline-block;
}
.givingBackTab,
.iconParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
}
.iconParent {
  gap: 1.5rem 0;
}
.bottomLine {
  align-self: stretch;
  height: 1rem;
  position: relative;
  background-color: var(--primary);
  z-index: 1;
}
.item {
  flex: 1;
  background-color: var(--light-orange);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-45xl);
  min-width: 19.25rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--heading-h4-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 750px) {
  .aboutUs {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
}
@media screen and (max-width: 450px) {
  .aboutUs {
    font-size: var(--font-size-lgi);
    line-height: 1.625rem;
  }
  .item {
    gap: var(--gap-13xl);
    padding-top: var(--padding-33xl);
    box-sizing: border-box;
  }
}
