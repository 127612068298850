.background {
  width: 27.188rem;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--light-orange);
}
.credentialOrdination {
  margin: 0;
  width: 25.625rem;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  z-index: 1;
}
.thisOrdinationCredential {
  width: 16rem;
  position: relative;
  line-height: 1.5rem;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}
.logoFrame {
  width: 25.938rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-4xs) 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--paragraph-size);
  color: var(--color-gray-400);
}
.addressDetails {
  position: absolute;
  top: 1.188rem;
  right: 0.594rem;
  border-right: 1.5px solid var(--bl);
  box-sizing: border-box;
  width: 0.094rem;
  height: 0.469rem;
}
.circle,
.icon {
  width: 1.25rem;
  z-index: 1;
}
.circle {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--br-smi);
  border: 1.5px solid var(--bl);
  box-sizing: border-box;
  height: 1.25rem;
}
.icon {
  height: 1.563rem;
  position: relative;
}
.messageFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
}
.kerala683642India,
.kochiErnakulam,
.pOBox37,
.perumbavoorPO {
  margin: 0;
}
.pOBox37Container {
  align-self: stretch;
  flex: 1;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.linksContainer,
.quicklinksText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.quicklinksText {
  align-self: stretch;
  width: 13.75rem;
  gap: 0 1rem;
}
.linksContainer {
  width: 25.813rem;
  height: 4.375rem;
  padding: 0 0 var(--padding-7xs) 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-sm);
}
.comeJoinUs {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.joinUsCallToAction {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-58xl);
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-black);
}
.imageIcon,
.ministrialText {
  position: relative;
  max-width: 100%;
}
.ministrialText {
  width: 30.188rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-54xl) 0 var(--padding-75xl);
  box-sizing: border-box;
  gap: 1.375rem 0;
  min-width: 30.188rem;
}
.imageIcon {
  height: 32rem;
  flex: 1;
  overflow: hidden;
  object-fit: cover;
  min-width: 34.313rem;
  margin-left: -3rem;
}
.credentialFrame,
.footerParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.credentialFrame {
  flex: 1;
  row-gap: 20px;
}
.footerParent {
  width: 83.625rem;
  padding: 0 var(--padding-19xl) var(--padding-4xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-h3-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .ministrialText {
    flex: 1;
  }
  .imageIcon {
    margin-left: 0;
  }
  .credentialFrame {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .credentialOrdination {
    font-size: var(--heading-h4-size);
    line-height: 2.813rem;
  }
  .comeJoinUs {
    font-size: var(--heading-h5-size);
    line-height: 0.813rem;
  }
  .ministrialText {
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-42xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .imageIcon {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .credentialOrdination {
    font-size: var(--heading-h5-size);
    line-height: 2.125rem;
  }
  .comeJoinUs {
    font-size: var(--label-03-size);
    line-height: 0.625rem;
  }
  .joinUsCallToAction {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
