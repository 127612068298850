.backgroundIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 96.313rem;
  height: 46rem;
  object-fit: cover;
}
.mainBackground {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--white);
  backdrop-filter: blur(40px);
  width: 100%;
  height: 100%;
  display: none;
}
.bottomLine {
  position: absolute;
  top: 29rem;
  left: 0;
  background-color: var(--primary);
  width: 49.313rem;
  height: 1rem;
  z-index: 1;
}
.quoteIcon {
  position: absolute;
  top: 10.625rem;
  left: 34.225rem;
  width: 12.006rem;
  height: 8.75rem;
  z-index: 2;
}
.buttonText {
  position: relative;
  font-size: var(--paragraph-size);
  line-height: 1rem;
  text-transform: uppercase;
  font-family: var(--paragraph);
  color: var(--black);
  text-align: center;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xl) var(--padding-45xl);
  background-color: var(--primary);
  position: absolute;
  top: 21.5rem;
  left: 3.863rem;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}
.button:hover {
  background-color: var(--color-burlywood);
}
.loremIpsumDolor,
.weWantTo {
  position: absolute;
  left: 3.081rem;
  display: inline-block;
  z-index: 2;
}
.weWantTo {
  margin: 0;
  top: 4.5rem;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  width: 23.631rem;
}
.loremIpsumDolor {
  top: 17.5rem;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  color: var(--color-gray-400);
  width: 19.394rem;
}
.testimonialBackground {
  position: absolute;
  top: 8rem;
  left: 23.5rem;
  background-color: var(--white);
  backdrop-filter: blur(40px);
  width: 49.313rem;
  height: 30rem;
  z-index: 1;
}
.backgroundParent {
  margin-left: -1.187rem;
  width: 96.313rem;
  height: 46rem;
  position: relative;
  max-width: 103%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--heading-h2-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 750px) {
  .weWantTo {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
}
@media screen and (max-width: 450px) {
  .weWantTo {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
}
