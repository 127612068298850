.httpsunsplashcomphotos3xIcon {
  height: 20rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 20.313rem;
}
.leadershipTraining,
.thereIsA {
  position: relative;
  display: inline-block;
}
.leadershipTraining {
  margin: 0;
  width: 25.625rem;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  max-width: 100%;
}
.thereIsA {
  width: 29.75rem;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  color: var(--color-gray-400);
  white-space: pre-wrap;
}
.inputField,
.inputFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.inputField {
  gap: 1rem 0;
}
.inputFieldWrapper {
  padding: 0 0 var(--padding-15xl);
  box-sizing: border-box;
  min-width: 29.75rem;
}
.httpsunsplashcomphotos3xParent,
.subNavigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.httpsunsplashcomphotos3xParent {
  flex: 1;
  align-items: flex-end;
  gap: 0 3rem;
}
.subNavigation {
  width: 66.813rem;
  align-items: flex-start;
  padding: 0 var(--padding-xl) var(--padding-13xl) var(--padding-6xl);
  box-sizing: border-box;
}
.httpsunsplashcomphotos3xIcon1 {
  height: 20rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 20.313rem;
}
.allAreWelcome {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.loremIpsumDolor {
  width: 29.75rem;
  position: relative;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  color: var(--color-gray-400);
  display: inline-block;
}
.linksColumnFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: 1rem 0;
  min-width: 29.75rem;
  max-width: 100%;
}
.frameFrame,
.httpsunsplashcomphotos3xGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.httpsunsplashcomphotos3xGroup {
  flex: 1;
  align-items: center;
  gap: 0 3rem;
}
.frameFrame {
  width: 66.813rem;
  align-items: flex-start;
  padding: 0 var(--padding-xl) var(--padding-80xl) var(--padding-6xl);
  box-sizing: border-box;
}
.meetOurInspirational {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.background {
  width: 18.875rem;
  height: 22.875rem;
  position: relative;
  background-color: var(--grey);
  display: none;
}
.httpswwwpexelscomphotowIcon {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.105);
}
.wrapperHttpswwwpexelscom {
  width: 9.5rem;
  height: 9.5rem;
  position: relative;
  border-radius: var(--br-187xl);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.joseCarmel {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.agmiPresident {
  position: relative;
  line-height: 1.5rem;
  z-index: 1;
}
.facebookNegative,
.instaFillIcon,
.twitterNegative {
  height: 1rem;
  width: 1rem;
  position: relative;
  min-height: 1rem;
  z-index: 1;
}
.facebookNegative,
.twitterNegative {
  overflow: hidden;
  flex-shrink: 0;
}
.instaFillIcon {
  object-fit: cover;
}
.socialLink,
.socialLink1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.socialLink1 {
  gap: 0 1.25rem;
}
.socialLink {
  padding: 0 var(--padding-7xs);
}
.personName2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-xs);
  gap: 1rem 0;
  font-size: var(--paragraph-size);
  color: var(--color-gray-500);
}
.personName,
.personName1,
.personName2 {
  display: flex;
  justify-content: flex-start;
}
.personName1 {
  flex-direction: column;
  align-items: center;
  gap: 0.5rem 0;
}
.personName {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-10xl) 0 var(--padding-xl);
}
.background1,
.item {
  background-color: var(--grey);
}
.item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-29xl);
  box-sizing: border-box;
  gap: var(--gap-7xl);
  min-width: 14.125rem;
}
.background1 {
  width: 18.875rem;
  height: 22.875rem;
  position: relative;
  display: none;
}
.httpswwwpexelscomphotopIcon {
  height: 100%;
  width: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.105);
}
.wrapperHttpswwwpexelscom1 {
  height: 9.5rem;
  width: 9.5rem;
  position: relative;
  border-radius: var(--br-187xl);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapperHttpswwwpexelscomWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
}
.jancyCarmel {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.agmiSecretary {
  position: relative;
  line-height: 1.5rem;
  z-index: 1;
}
.facebookNegative1,
.instaFillIcon1,
.twitterNegative1 {
  height: 1rem;
  width: 1rem;
  position: relative;
  min-height: 1rem;
  z-index: 1;
}
.facebookNegative1,
.twitterNegative1 {
  overflow: hidden;
  flex-shrink: 0;
}
.instaFillIcon1 {
  object-fit: cover;
}
.footerBackgroundShape {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 var(--padding-xs);
  gap: 0 1.25rem;
}
.secretaryFrame1 {
  flex-direction: column;
  align-items: center;
  gap: 1rem 0;
}
.jancyCarmelParent,
.secretaryFrame,
.secretaryFrame1 {
  display: flex;
  justify-content: flex-start;
}
.secretaryFrame {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-10xs) 0 0;
  font-size: var(--paragraph-size);
  color: var(--color-gray-500);
}
.jancyCarmelParent {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem 0;
}
.background2,
.item1 {
  background-color: var(--grey);
}
.item1 {
  flex: 0.7476;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-29xl) var(--padding-54xl) var(--padding-29xl)
    var(--padding-56xl);
  box-sizing: border-box;
  gap: var(--gap-7xl);
  min-width: 14.125rem;
}
.background2 {
  width: 18.875rem;
  height: 22.875rem;
  position: relative;
  display: none;
}
.imageIcon {
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.105);
}
.name,
.wrapperImage {
  position: relative;
  z-index: 1;
}
.wrapperImage {
  width: 9.5rem;
  height: 9.5rem;
  border-radius: var(--br-187xl);
  display: flex;
  align-items: center;
  justify-content: center;
}
.name {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.nameWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs) 0 0;
}
.pastorChurch {
  position: relative;
  line-height: 1.5rem;
  z-index: 1;
}
.pastorChurchWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-11xs);
}
.facebookNegative2,
.instaFillIcon2,
.twitterNegative2 {
  height: 1rem;
  width: 1rem;
  position: relative;
  min-height: 1rem;
  z-index: 2;
}
.facebookNegative2,
.twitterNegative2 {
  overflow: hidden;
  flex-shrink: 0;
}
.instaFillIcon2 {
  object-fit: cover;
}
.footerBackgroundShape1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 1.25rem;
}
.frameGroup,
.frameParent,
.item2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameGroup {
  align-items: flex-start;
  gap: 1rem 0;
  font-size: var(--paragraph-size);
  color: var(--color-gray-500);
}
.frameParent,
.item2 {
  align-items: center;
}
.frameParent {
  gap: 0.5rem 0;
}
.item2 {
  flex: 1;
  background-color: var(--grey);
  padding: var(--padding-29xl);
  box-sizing: border-box;
  gap: var(--gap-7xl);
  min-width: 14.125rem;
}
.socialMediaIcon {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0 1.5rem;
  text-align: left;
  font-size: var(--heading-h5-size);
}
.teamSection {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 4rem 0;
}
.sectionTitle,
.teamSection,
.teamSectionWrapper {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.teamSectionWrapper {
  width: 59.938rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-8xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--heading-h2-size);
}
.sectionTitle {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 4rem 0;
  text-align: left;
  font-size: var(--heading-h4-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .inputFieldWrapper {
    flex: 1;
  }
  .httpsunsplashcomphotos3xParent {
    flex-wrap: wrap;
  }
  .linksColumnFrame {
    flex: 1;
  }
  .httpsunsplashcomphotos3xGroup,
  .socialMediaIcon {
    flex-wrap: wrap;
  }
  .sectionTitle,
  .teamSection {
    gap: 2rem 0;
  }
}
@media screen and (max-width: 750px) {
  .leadershipTraining {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
  .inputFieldWrapper {
    min-width: 100%;
  }
  .httpsunsplashcomphotos3xParent {
    gap: 0 1.5rem;
  }
  .allAreWelcome {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
  .linksColumnFrame {
    min-width: 100%;
  }
  .httpsunsplashcomphotos3xGroup {
    gap: 0 1.5rem;
  }
  .meetOurInspirational {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .sectionTitle,
  .teamSection {
    gap: 1rem 0;
  }
}
@media screen and (max-width: 450px) {
  .allAreWelcome,
  .leadershipTraining {
    font-size: var(--font-size-lgi);
    line-height: 1.625rem;
  }
  .frameFrame {
    padding-bottom: var(--padding-45xl);
    box-sizing: border-box;
  }
  .meetOurInspirational {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .joseCarmel {
    font-size: var(--font-size-lgi);
  }
  .item {
    padding: var(--padding-12xl) var(--padding-xl);
    box-sizing: border-box;
  }
  .jancyCarmel {
    font-size: var(--font-size-lgi);
  }
  .item1 {
    padding: var(--padding-12xl) var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
  }
  .name {
    font-size: var(--font-size-lgi);
  }
  .item2 {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
}
