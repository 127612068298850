@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --paragraph: "Roboto Condensed";

  /* font sizes */
  --paragraph-size: 1rem;
  --heading-h4-size: 2rem;
  --font-size-lgi: 1.188rem;
  --font-size-7xl: 1.625rem;
  --cap-02-size: 0.75rem;
  --font-size-sm: 0.875rem;
  --heading-h2-size: 3rem;
  --font-size-10xl: 1.813rem;
  --font-size-19xl: 2.375rem;
  --heading-h1-size: 4rem;
  --font-size-32xl: 3.188rem;
  --heading-h5-size: 1.5rem;
  --label-03-size: 1.125rem;
  --font-size-11xl: 1.875rem;
  --heading-h3-size: 2.5rem;
  --font-size-xl: 1.25rem;

  /* Colors */
  --white: #fff;
  --primary: #ffd2a4;
  --color-burlywood: #e6b88a;
  --color-gray-900: #232536;
  --black: #1c1e28;
  --dark: #1e1b1b;
  --bl: #161722;
  --color-gray-100: #0d0d0d;
  --color-gray-500: rgba(22, 23, 34, 0.7);
  --color-gray-200: rgba(30, 27, 27, 0.7);
  --color-gray-300: rgba(255, 255, 255, 0.9);
  --color-gray-400: rgba(22, 23, 34, 0.78);
  --color-gray-600: rgba(255, 255, 255, 0.78);
  --color-gray-700: rgba(255, 255, 255, 0.8);
  --color-gray-800: rgba(255, 255, 255, 0.15);
  --light-orange: #fff5eb;
  --grey: #f5f2f0;
  --color-black: #000;
  --color-dimgray-200: rgba(69, 73, 105, 0.78);

  /* Gaps */
  --gap-xl: 1.25rem;
  --gap-0: 0rem;
  --gap-13xl: 2rem;
  --gap-base: 1rem;
  --gap-mini: 0.938rem;
  --gap-5xl: 1.5rem;
  --gap-xs: 0.75rem;
  --gap-5xs: 0.5rem;
  --gap-sm: 0.875rem;
  --gap-29xl: 3rem;
  --gap-45xl: 4rem;
  --gap-24xl: 2.688rem;
  --gap-2xl: 1.313rem;
  --gap-3xs: 0.625rem;
  --gap-8xs: 0.313rem;
  --gap-12xl: 1.938rem;
  --gap-23xl: 2.625rem;
  --gap-109xl: 8rem;
  --gap-3xl: 1.375rem;
  --gap-21xl: 2.5rem;
  --gap-62xl: 5.063rem;
  --gap-33xl: 3.25rem;
  --gap-7xl: 1.625rem;

  /* Paddings */
  --padding-45xl: 4rem;
  --padding-17xl: 2.25rem;
  --padding-66xl: 5.313rem;
  --padding-xl: 1.25rem;
  --padding-23xl: 2.625rem;
  --padding-35xl: 3.375rem;
  --padding-12xs: 0.063rem;
  --padding-5xl: 1.5rem;
  --padding-10xl: 1.813rem;
  --padding-11xs: 0.125rem;
  --padding-11xl: 1.875rem;
  --padding-base: 1rem;
  --padding-22xl: 2.563rem;
  --padding-3xl: 1.375rem;
  --padding-40xl: 3.688rem;
  --padding-19xl: 2.375rem;
  --padding-29xl: 3rem;
  --padding-12xl: 1.938rem;
  --padding-3xs: 0.625rem;
  --padding-144xl: 10.188rem;
  --padding-87xl: 6.625rem;
  --padding-61xl: 5rem;
  --padding-33xl: 3.25rem;
  --padding-50xl: 4.313rem;
  --padding-109xl: 8rem;
  --padding-81xl: 6.25rem;
  --padding-31xl: 3.125rem;
  --padding-64xl: 5.188rem;
  --padding-xs: 0.75rem;
  --padding-47xl: 4.125rem;
  --padding-25xl: 2.75rem;
  --padding-18xl: 2.313rem;
  --padding-4xs: 0.563rem;
  --padding-lg: 1.125rem;
  --padding-91xl: 6.875rem;
  --padding-36xl: 3.438rem;
  --padding-sm: 0.875rem;
  --padding-119xl: 8.625rem;
  --padding-71xl: 5.625rem;
  --padding-58xl: 4.813rem;
  --padding-141xl: 10rem;
  --padding-85xl: 6.5rem;
  --padding-103xl: 7.625rem;
  --padding-42xl: 3.813rem;
  --padding-26xl: 2.813rem;
  --padding-8xs: 0.313rem;
  --padding-4xl: 1.438rem;
  --padding-54xl: 4.563rem;
  --padding-75xl: 5.875rem;
  --padding-28xl: 2.938rem;
  --padding-7xs: 0.375rem;
  --padding-5xs: 0.5rem;
  --padding-67xl: 5.375rem;
  --padding-77xl: 6rem;
  --padding-37xl: 3.5rem;
  --padding-43xl: 3.875rem;
  --padding-62xl: 5.063rem;
  --padding-34xl: 3.313rem;
  --padding-21xl: 2.5rem;
  --padding-56xl: 4.688rem;
  --padding-78xl: 6.063rem;
  --padding-44xl: 3.938rem;
  --padding-2xs: 0.688rem;
  --padding-16xl: 2.188rem;
  --padding-mid: 1.063rem;
  --padding-6xl: 1.563rem;
  --padding-10xs: 0.188rem;
  --padding-80xl: 6.188rem;
  --padding-13xl: 2rem;
  --padding-15xl: 2.125rem;
  --padding-2xl: 1.313rem;

  /* Border radiuses */
  --br-xs: 12px;
  --br-11xl: 30px;
  --br-13xl: 32px;
  --br-9xs: 4px;
  --br-smi: 13px;
  --br-187xl: 206px;
}
