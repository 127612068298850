.loremIpsumDolor,
.loveAndCompassion {
  position: relative;
  display: inline-block;
}
.loveAndCompassion {
  margin: 0;
  width: 34rem;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  max-width: 100%;
}
.loremIpsumDolor {
  width: 52.875rem;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  color: var(--color-gray-400);
}
.loveandcompassion,
.quoteIconVector {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.quoteIconVector {
  flex-direction: column;
  align-items: center;
  gap: 2rem 0;
}
.loveandcompassion {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-3xs);
  box-sizing: border-box;
}
.testimonialFrame {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem 0;
  max-width: 100%;
}
.home,
.homeHeaderFrame,
.testimonialFrame {
  display: flex;
  justify-content: flex-start;
}
.homeHeaderFrame {
  width: 66.625rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-3xl) 5.688rem var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--heading-h2-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
.home {
  width: 100%;
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  letter-spacing: normal;
}
@media screen and (max-width: 1100px) {
  .homeHeaderFrame {
    padding-bottom: var(--padding-40xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .loveAndCompassion {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .testimonialFrame {
    gap: 1.75rem 0;
  }
  .homeHeaderFrame {
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .loveAndCompassion {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .quoteIconVector {
    gap: 1rem 0;
  }
}
