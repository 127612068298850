.imageIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.aboutUs1,
.servingTheWorld {
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.servingTheWorld {
  margin: 0;
  font-size: var(--heading-h2-size);
  letter-spacing: -0.01em;
  line-height: 4rem;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.aboutUsHero,
.inputField {
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.aboutUsHero {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-141xl) var(--padding-91xl);
  position: relative;
  gap: var(--gap-base);
}
.inputField {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-109xl);
}
.aboutUs,
.inputField,
.pageContent {
  display: flex;
  justify-content: flex-start;
}
.pageContent {
  width: 70.75rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-6xl) var(--padding-109xl) var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.aboutUs {
  width: 100%;
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1275px) {
  .pageContent {
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) {
  .aboutUsHero {
    padding-left: var(--padding-36xl);
    padding-right: var(--padding-36xl);
    box-sizing: border-box;
  }
  .pageContent {
    padding-bottom: var(--padding-16xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .servingTheWorld {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .inputField,
  .pageContent {
    padding-bottom: var(--padding-64xl);
    box-sizing: border-box;
  }
  .pageContent {
    padding-bottom: var(--padding-4xl);
  }
}
@media screen and (max-width: 450px) {
  .servingTheWorld {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .aboutUsHero {
    padding: var(--padding-85xl) var(--padding-xl);
    box-sizing: border-box;
  }
}
