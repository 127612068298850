.welcomeToOur {
  flex: 1;
  position: relative;
  text-transform: uppercase;
  flex-shrink: 0;
}
.contactLink {
  width: 13.125rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
  box-sizing: border-box;
}
.loveAndCompassion {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--heading-h2-size);
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.sermonsLink {
  width: 36.063rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem 0;
  max-width: 100%;
}
.emailInput {
  width: 50.906rem;
  position: relative;
  line-height: 1.5rem;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.inspirationalTeamFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-base);
  box-sizing: border-box;
  max-width: 100%;
  color: var(--color-gray-400);
}
.httpswwwpexelscomphotoaIcon {
  height: 24rem;
  width: 19.419rem;
  position: relative;
  border-radius: var(--br-11xl);
  object-fit: cover;
}
.httpswwwpexelscomphotomIcon {
  height: 32rem;
  flex: 1;
  position: relative;
  border-radius: var(--br-13xl);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 16.813rem;
}
.httpswwwpexelscomphotopIcon {
  height: 24rem;
  width: 19.419rem;
  position: relative;
  border-radius: var(--br-11xl);
  object-fit: cover;
}
.logoFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-13xl);
  box-sizing: border-box;
  gap: 0 1.625rem;
  max-width: 100%;
}
.ourMission,
.strivingForA {
  position: relative;
  text-transform: uppercase;
}
.strivingForA {
  margin: 0;
  width: 24.588rem;
  font-size: var(--heading-h4-size);
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.arrangeBibleConferences,
.establishLocalChurches,
.helpThePoor,
.shareTheGospel,
.trainMenAnd {
  margin-bottom: false;
}
.trainMenAndWomenInTheolog {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
}
.trainMenAndContainer {
  width: 24.656rem;
  position: relative;
  line-height: 1.5rem;
  color: var(--color-gray-400);
  display: inline-block;
}
.linksFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem 0;
  min-width: 24.688rem;
  max-width: 100%;
}
.whatWeDo {
  position: relative;
  text-transform: uppercase;
}
.bringingPeaceAnd,
.loremIpsumDolor {
  width: 24.588rem;
  position: relative;
  display: inline-block;
}
.bringingPeaceAnd {
  margin: 0;
  font-size: var(--heading-h4-size);
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.loremIpsumDolor {
  line-height: 1.5rem;
  color: var(--color-gray-400);
}
.instagramIconFrame,
.messageInstance {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.instagramIconFrame {
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem 0;
  min-width: 24.563rem;
}
.messageInstance {
  flex-direction: row;
  justify-content: center;
  padding: 0 0 var(--padding-base);
  box-sizing: border-box;
  gap: 0 1.625rem;
  text-align: left;
}
.benefitsOfOur {
  margin: 0;
  width: 30.563rem;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logo,
.twitterFrame {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.twitterFrame {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-11xl) 0 0;
  box-sizing: border-box;
  font-size: var(--heading-h2-size);
}
.logo {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 2rem 0;
  text-align: center;
  font-size: var(--paragraph-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .instagramIconFrame,
  .linksFrame {
    flex: 1;
  }
  .messageInstance {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .loveAndCompassion {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .strivingForA {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
  .linksFrame {
    min-width: 100%;
  }
  .bringingPeaceAnd {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
  .instagramIconFrame {
    min-width: 100%;
  }
  .benefitsOfOur {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .logo {
    gap: 1rem 0;
  }
}
@media screen and (max-width: 450px) {
  .loveAndCompassion {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .bringingPeaceAnd,
  .strivingForA {
    font-size: var(--font-size-lgi);
    line-height: 1.625rem;
  }
  .benefitsOfOur {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
}
