.findFulfillmentAnd,
.loremIpsumDolor {
  position: relative;
  display: inline-block;
}
.findFulfillmentAnd {
  margin: 0;
  width: 25.625rem;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  max-width: 100%;
}
.loremIpsumDolor {
  width: 31.25rem;
  font-size: var(--paragraph-size);
  line-height: 1.5rem;
  color: var(--color-gray-400);
}
.inspirationalTeamFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: 1rem 0;
  max-width: 100%;
}
.httpsunsplashcomphotos3mIcon {
  height: 20rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 20.313rem;
}
.instagramFillFrame,
.subNavigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.instagramFillFrame {
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 1.5rem;
}
.subNavigation {
  width: 66.813rem;
  align-items: flex-start;
  padding: 0 var(--padding-xl) var(--padding-13xl) var(--padding-6xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--heading-h4-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 750px) {
  .findFulfillmentAnd {
    font-size: var(--font-size-7xl);
    line-height: 2.188rem;
  }
}
@media screen and (max-width: 450px) {
  .findFulfillmentAnd {
    font-size: var(--font-size-lgi);
    line-height: 1.625rem;
  }
}
