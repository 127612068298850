.background {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
}
.agmiLogo1Icon {
  height: 3.75rem;
  width: 3.188rem;
  position: relative;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
}
.agmi {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 2.063rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  white-space: nowrap;
}
.logo,
.navbuttonINSTANCE {
  display: flex;
  flex-direction: row;
}
.logo {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--padding-12xs);
  cursor: pointer;
  z-index: 1;
}
.navbuttonINSTANCE {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 0;
}
.abundantGraceMinistries {
  position: relative;
  font-size: var(--paragraph-size);
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
}
.linkslistFRAME {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem 0;
}
.linkslistFRAMEWrapper {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-11xs);
}
.backgroundRECTANGLE,
.linkslistFRAMEWrapper,
.navbarINSTANCE {
  display: flex;
  justify-content: flex-start;
}
.navbarINSTANCE {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 2.125rem;
}
.backgroundRECTANGLE {
  width: 20.313rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-8xs);
  box-sizing: border-box;
  max-width: 100%;
}
.divider,
.home {
  position: relative;
}
.divider {
  align-self: stretch;
  width: 0;
  opacity: 0.1;
}
.home {
  flex: 1;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
}
.dividerParent {
  align-self: stretch;
  flex: 0.6783;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-18xl) 0 0;
  gap: 0 0.5rem;
  text-align: right;
}
.aboutUs,
.ministry {
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
}
.aboutUs {
  flex: 1;
  white-space: nowrap;
}
.ministry {
  align-self: stretch;
}
.ministryWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.buttonText,
.linksList {
  font-size: var(--paragraph-size);
}
.linksList {
  align-self: stretch;
  width: 25.813rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-23xl);
  max-width: 100%;
  z-index: 1;
  color: var(--color-gray-300);
}
.buttonText {
  position: relative;
  line-height: 1rem;
  text-transform: uppercase;
  font-family: var(--paragraph);
  color: var(--black);
  text-align: center;
}
.navButton,
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl) var(--padding-26xl) var(--padding-xl)
    var(--padding-29xl);
  background-color: var(--primary);
  border-radius: var(--br-xs);
  backdrop-filter: blur(80px);
  justify-content: center;
  white-space: nowrap;
  z-index: 1;
}
.navButton:hover {
  background-color: var(--color-burlywood);
}
.navbar {
  align-self: stretch;
  height: 5rem;
  justify-content: space-between;
  padding: 0 var(--padding-91xl) 0 var(--padding-103xl);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 99;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--heading-h4-size);
  color: var(--white);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .abundantGraceMinistries {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .navbar {
    padding-left: var(--padding-42xl);
    padding-right: var(--padding-36xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .navbarINSTANCE {
    gap: 0 1.063rem;
  }
  .linksList {
    display: none;
    gap: var(--gap-2xl);
  }
  .navbar {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
