.httpswwwpexelscomphotoaIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-11xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.testimonialImageFrame {
  height: 27rem;
  width: 18.313rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-29xl) 0 0;
  box-sizing: border-box;
}
.buttonText {
  position: relative;
  font-size: var(--paragraph-size);
  line-height: 1rem;
  text-transform: uppercase;
  font-family: var(--paragraph);
  color: var(--black);
  text-align: center;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xl);
  background-color: var(--primary);
  width: 12.813rem;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}
.button:hover {
  background-color: var(--color-burlywood);
}
.httpswwwpexelscomphotomIcon {
  align-self: stretch;
  height: 32rem;
  position: relative;
  border-radius: var(--br-13xl);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.buttonParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem 0;
  min-width: 15.813rem;
  max-width: 100%;
}
.httpswwwpexelscomphotopIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-11xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.backgroundShapeRectangle,
.testimonialImageFrame1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimonialImageFrame1 {
  height: 27rem;
  width: 18.313rem;
  flex-direction: column;
  padding: var(--padding-29xl) 0 0;
  box-sizing: border-box;
}
.backgroundShapeRectangle {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 1.5rem;
  max-width: 100%;
}
@media screen and (max-width: 450px) {
  .testimonialImageFrame {
    padding-top: var(--padding-12xl);
    box-sizing: border-box;
  }
  .buttonParent {
    gap: 1.5rem 0;
  }
  .testimonialImageFrame1 {
    padding-top: var(--padding-12xl);
    box-sizing: border-box;
  }
}
