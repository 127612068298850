.missionConference {
  margin: 0;
  width: 25.938rem;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--light-orange);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.clockIcon {
  position: absolute;
  top: 15.75rem;
  left: 3.813rem;
  width: 1.25rem;
  height: 1.25rem;
  z-index: 2;
}
.backgroundParent {
  width: 27.188rem;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 0;
}
.encourageEquipAnd {
  margin: 0;
  height: 5.25rem;
  width: 18.688rem;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}
.connectBox {
  width: 25.938rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-4xs) 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--heading-h5-size);
  color: var(--color-gray-400);
}
.sunday2024,
.time9Am {
  margin: 0;
}
.sunday2024TimeContainer {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
  z-index: 2;
}
.backgroundShape,
.credentialSection {
  display: flex;
  justify-content: flex-start;
}
.credentialSection {
  width: 11.75rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-12xl) 0 var(--padding-xl);
  box-sizing: border-box;
}
.backgroundShape {
  align-self: stretch;
  width: 13.75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem 0;
}
.backgroundShapeWrapper {
  width: 25.813rem;
  height: 9.313rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-4xs) 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--paragraph-size);
}
.comeJoinUs {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  z-index: 2;
}
.connectBox1,
.missionConferenceParent {
  display: flex;
  justify-content: flex-start;
}
.connectBox1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-40xl);
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--color-black);
}
.missionConferenceParent {
  width: 29.625rem;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-50xl) 0 var(--padding-62xl);
  box-sizing: border-box;
  position: relative;
  gap: 1rem 0;
  min-width: 29.625rem;
  max-width: 100%;
}
.imageIcon {
  height: 32rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 34.313rem;
  margin-left: -2.437rem;
}
.line {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
  text-align: left;
  font-size: var(--heading-h3-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .missionConferenceParent {
    flex: 1;
  }
  .imageIcon {
    margin-left: 0;
  }
  .line {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .missionConference {
    font-size: var(--heading-h4-size);
    line-height: 2.813rem;
  }
  .comeJoinUs {
    font-size: var(--heading-h5-size);
    line-height: 0.813rem;
  }
  .missionConferenceParent {
    padding-top: var(--padding-26xl);
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .imageIcon {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .missionConference {
    font-size: var(--heading-h5-size);
    line-height: 2.125rem;
  }
  .encourageEquipAnd {
    font-size: var(--font-size-lgi);
  }
  .comeJoinUs {
    font-size: var(--label-03-size);
    line-height: 0.625rem;
  }
  .connectBox1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
