.footerBackgroundParent,
.sermons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8rem 0;
}
.footerBackgroundParent {
  width: 82.5rem;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.sermons {
  width: 100%;
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  align-items: center;
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .footerBackgroundParent {
    gap: 4rem 0;
  }
}
@media screen and (max-width: 750px) {
  .footerBackgroundParent {
    gap: 2rem 0;
  }
  .sermons {
    gap: 4rem 0;
  }
}
@media screen and (max-width: 450px) {
  .footerBackgroundParent {
    gap: 1rem 0;
  }
  .sermons {
    gap: 2rem 0;
  }
}
