.httpsunsplashcomphotos6lIcon {
  width: 93.75rem;
  height: 30.188rem;
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 100%;
}
.contact1,
.getInTouch {
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.getInTouch {
  margin: 0;
  width: 25.563rem;
  font-size: var(--heading-h2-size);
  letter-spacing: -0.01em;
  line-height: 4rem;
  font-weight: 700;
  font-family: inherit;
  color: var(--white);
  display: inline-block;
  max-width: 100%;
}
.header {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-141xl) var(--padding-91xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  background-image: url(/public/httpsunsplashcomphotos6liebveafry@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
}
.addressText,
.contact,
.header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.addressText {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 7.375rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--primary);
  font-family: var(--paragraph);
}
.contact {
  width: 100%;
  position: relative;
  background-color: var(--grey);
  overflow: hidden;
  flex-direction: column;
  letter-spacing: normal;
}
@media screen and (max-width: 750px) {
  .getInTouch {
    font-size: var(--font-size-19xl);
    line-height: 3.188rem;
  }
  .header {
    padding: var(--padding-85xl) var(--padding-36xl);
    box-sizing: border-box;
  }
  .addressText {
    padding-bottom: var(--padding-58xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .getInTouch {
    font-size: var(--font-size-10xl);
    line-height: 2.375rem;
  }
  .header {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
