.background {
  width: 27.188rem;
  height: 32rem;
  position: relative;
  background-color: var(--light-orange);
  display: none;
  max-width: 100%;
}
.localChurches,
.whereTwoOr {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.localChurches {
  line-height: 3.5rem;
}
.whereTwoOr {
  height: 5.25rem;
  width: 20.75rem;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.frameCta {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-sm);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--heading-h5-size);
  color: var(--color-gray-400);
}
.clockIcon {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  z-index: 1;
}
.textCredentialSubtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}
.sunday2024,
.time9Am {
  margin: 0;
}
.sunday2024TimeContainer {
  flex: 1;
  position: relative;
  line-height: 1.5rem;
  z-index: 1;
}
.textCredentialTitle {
  width: 10.813rem;
  flex-direction: row;
  gap: 0 1rem;
}
.frameCredential,
.image,
.textCredentialTitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameCredential {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: 0.75rem 0;
}
.image {
  width: 14rem;
  height: 9.5rem;
  flex-direction: row;
  padding: 0 var(--padding-11xs) var(--padding-xs);
  box-sizing: border-box;
  font-size: var(--paragraph-size);
}
.comeJoinUs {
  margin: 0;
  position: relative;
  font-size: var(--font-size-11xl);
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  color: var(--color-black);
  text-align: center;
  z-index: 2;
}
.content {
  background-color: var(--light-orange);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-42xl) var(--padding-25xl) var(--padding-62xl)
    var(--padding-40xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  min-width: 27.188rem;
  max-width: 100%;
  z-index: 1;
}
.imageIcon {
  height: 32rem;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 34.313rem;
}
.line {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
  text-align: left;
  font-size: var(--heading-h3-size);
  color: var(--bl);
  font-family: var(--paragraph);
}
@media screen and (max-width: 1100px) {
  .content {
    flex: 1;
  }
  .line {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .localChurches {
    font-size: var(--heading-h4-size);
    line-height: 2.813rem;
  }
  .comeJoinUs {
    font-size: var(--heading-h5-size);
    line-height: 0.813rem;
  }
  .content {
    padding-top: var(--padding-21xl);
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
  }
  .content,
  .imageIcon {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .localChurches {
    font-size: var(--heading-h5-size);
    line-height: 2.125rem;
  }
  .whereTwoOr {
    font-size: var(--font-size-lgi);
  }
  .comeJoinUs {
    font-size: var(--label-03-size);
    line-height: 0.625rem;
  }
  .content {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
